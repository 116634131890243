import React, { useState } from 'react'
import Landing from '../components/layout/Landing'
import TextField from '../components/elements/form/TextField'
import TextareaField from '../components/elements/form/TextareaField';
import FormButton from '../components/elements/form/FormButton';
import { useDispatch } from 'react-redux';
import { ERROR, SUCCESS } from '../store/types';
import axios from 'axios';
import { storeId } from '../utils';

const Contact = () => {
    const [contactPayload, setContactPayload] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const dispatch = useDispatch()
    const [processing, setProcessing] = useState(false);

    const validateForm = () => {
        let errors = {}
        if (!contactPayload.name || contactPayload.name === '') {
            errors.name = true
        }
        if (!contactPayload.email || contactPayload.email === '') {
            errors.email = true
        }

        if (!contactPayload?.enquiry || contactPayload.enquiry === '') {
            errors.enquiry = true
        }

        setValidationErrors(errors)
        return errors
    }

    const createContact = async () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
        
        setProcessing(true)
        const requestPayload = {...contactPayload, ...{store: storeId}}
    
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/enquiries`, requestPayload)
            dispatch({
                type: SUCCESS,
                payload: 'Thank you for contacting us. We will review your message and send you a request/message if necessary'
            })
            setContactPayload({})
            setProcessing(false)
        } catch (error) {
            console.log(error.response)
            dispatch({
                type: ERROR,
                error: error
            });
            setProcessing(false)
        }
    }

    return (
        <Landing>
            <section className='min-h-screen xl:flex items-center justify-between pt-[100px]'>
                <div className='w-full h-inherit xl:h-screen bg-gray-100 flex items-end px-8 xl:px-12 relative'>
                    <div className='hidden xl:block border border-nadabake-purple bg-nadabake-purple bg-opacity-5 w-[80%] h-[60vh] left-[25%] top-[5%] absolute'></div>
                    <div className='w-full lg:text-right mb-32 pt-[100px] xl:pt-0'>
                        <p className='font-bold text-6xl text-gray-300'>get in touch</p>
                        <p className='font-light text-lg text-gray-600 mb-2'>hello@nadabakehouse.com</p>
                        <p className='font-light text-lg text-gray-600 mb-6'>+234 801 234 5678</p>
                    </div>
                </div>
                <div className='w-full px-8 xl:px-32 py-12'>
                    <p className='font-bold text-3xl text-gray-400'>Leave us a message</p>
                    <div className='w-10/12 xl:w-1/2'>
                        <p className='font-light text-sm text-gray-600 mb-2'>You can fill this form to leave us a message and we'll get back to you right away</p>
                    </div>

                    <div className='w-full xl:w-10/12'>
                        <div className='my-8 w-full'>
                            <TextField
                                inputLabel="Your name" 
                                fieldId="name" 
                                inputType="text" 
                                preloadValue={''}
                                hasError={validationErrors.name} 
                                returnFieldValue={(value)=>{setContactPayload({...contactPayload, ...{name: value}})}}
                            />
                        </div>
                        <div className='my-8 w-full'>
                            <TextField
                                inputLabel="Your email address" 
                                fieldId="email" 
                                inputType="text" 
                                preloadValue={''}
                                hasError={validationErrors.email} 
                                returnFieldValue={(value)=>{setContactPayload({...contactPayload, ...{email: value}})}}
                            />
                            <p className='text-xs text-gray-400 mt-2'>Please use a valid email address. We will try to reach out to you here with a response if needed.</p>
                        </div>
                        <div className='my-8 w-full'>
                            <TextField
                                inputLabel="Your phone number" 
                                fieldId="phone" 
                                inputType="text" 
                                preloadValue={''}
                                hasError={validationErrors.phone} 
                                returnFieldValue={(value)=>{setContactPayload({...contactPayload, ...{phone: value}})}}
                            />
                            <p className='text-xs text-gray-400 mt-2'>If you would prefer a call back, please leave your phone number.</p>
                        </div>

                        <div className='my-8 w-full'>
                            <TextareaField
                                inputLabel="Your Message/Enquiry" 
                                fieldId="enquiry" 
                                inputType="text" 
                                preloadValue={''}
                                hasError={false} 
                                returnFieldValue={(value)=>{setContactPayload({...contactPayload, ...{enquiry: value}})}}
                            />
                        </div>

                        <div className='my-8 flex flex-row-reverse items-center justify-between'>
                            <div className='w-full'>
                            <FormButton buttonLabel={`Send Message`} buttonAction={()=>{createContact()}} processing={processing} />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </Landing>
    )
}

export default Contact