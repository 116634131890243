import React, { useEffect, useState } from 'react'
import Landing from '../components/layout/Landing'
import StoreItem from '../components/elements/StoreItem';
import PlusIcon from '../components/elements/icons/PlusIcon';
import CloseIcon from '../components/elements/icons/CloseIcon';
import ListBox from '../components/elements/ListBox';
import { fetchStoreMenu } from '../store/actions/menuActions';
import { fetchCategories } from '../store/actions/categoriesActions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/elements/Loader';
import { debounce, searchArray } from '../utils';

const Emenu = () => {
    const [activeCategory, setActiveCategory] = useState('all');
    const [categoriesOpen, setCategoriesOpen] = useState(false);
  
    const dispatch = useDispatch();
    const menuSelector = useSelector((state) => state.menu);
    const categoriesSelector = useSelector((state) => state.categories);
    useEffect(() => {
      dispatch(fetchStoreMenu());
      dispatch(fetchCategories())
      return () => {
      
      };
    }, [dispatch]);
  
    const sortingOptions = [
      {
        label: 'Price',
        value: 'price'
      },
      {
        label: 'Ratings',
        value: 'ratings'
      },
      {
        label: 'Sales',
        value: 'sales'
      },
      {
        label: 'Recently added',
        value: 'recent'
      }
    ]
  
    const [searchResults, setSearchResults] = useState(null);
    const [searching, setSearching] = useState(false);
  
    const performSearch = debounce((term) => {
      setSearching(true)
      let results = searchArray(menuSelector?.menu?.items.map((item)=>{return {...item, category: item?.parentItemCategory?._id}}), term)
      setSearchResults(results)
  
      setTimeout(() => {
        setSearching(false)
      }, 100);
    })
  
    const clearSearch = () => {
      setSearchResults(null)
    }
    
  
    return (
      <Landing>
        <section className="min-h-screen px-8 lg:px-24 xl:px-32 2xl:px-44 relative pt-24 mb-24">
        {/* <div className="w-[55%] h-[90vh] bg-nadabake-purple bg-opacity-10 absolute top-32 left-0 z-0"></div> */}
        {/* <h1 className="text-3xl font-bold leading-[1.2em] mb-6">The Store</h1> */}
        {/* <div className='w-full xl:w-1/2' data-aos="fade-up" data-aos-anchor-placement="top-center">
          <p className="uppercase tracking-[0.2em] mt-3 mb-3 text-nadabake-purple">Skip the line, indulge online</p>
          <p className="text-lg font-medium">From our oven to your doorstep, it&apos;s a journey of pure delight. Don&apos;t just take our word for it, try it for yourself!</p>
        </div> */}
  
        {/* <div className='flex items-center justify-between mb-12'>
          <div></div>
          
          <div className='flex flex-row-reverse items-center gap-x-3'>
            <div className='relative'>
              <ListBox options={sortingOptions} />
            </div>
            <p className='text-sm text-gray-400'>Sort by</p>
          </div>
        </div> */}
  
        {categoriesSelector.fetchingCategories ?
          <Loader />
         : <div className='w-full grid grid-cols-5 lg:grid-cols-10 items-center gap-8'>
            <div className='w-full'>
              <button onClick={()=>{
                setActiveCategory('all')
                clearSearch()
                }} className={`rounded-full w-[50px] h-[85px] text-center flex flex-col justify-top items-center transition duration-200`}>
                <div className={`flex items-center justify-center rounded-full w-[50px] h-[50px] transition duration-200 ${activeCategory === 'all' && 'bg-nadabake-purple bg-opacity-20'}`}>
                  <div className='rounded-full w-[40px] h-[40px] border border-gray-200 bg-nadabake-purple bg-opacity-10'></div>
                </div>
                <p className={`text-sm ${activeCategory === 'all' && 'text-nadabake-purple font-medium'}`}>All</p>
              </button>
            </div>
          {categoriesSelector?.categories?.categories?.map((cat, catIndex)=>(
            <div key={catIndex} className='w-full'>
              <button onClick={()=>{
                setActiveCategory(cat._id)
                performSearch(cat._id)
                }} className={`rounded-full w-[50px] h-[85px] text-center flex flex-col justify-top items-center transition duration-200`}>
                <div className={`flex items-center justify-center rounded-full w-[50px] h-[50px] transition duration-200 ${activeCategory === cat._id && 'bg-nadabake-purple bg-opacity-20'}`}>
                  <div className='rounded-full w-[40px] h-[40px] border border-gray-200 bg-nadabake-purple bg-opacity-10'></div>
                </div>
                <p className={`text-sm ${activeCategory === cat._id && 'text-nadabake-purple font-medium'}`}>{cat.name}</p>
              </button>
            </div>
          ))}
          {/* {categoriesSelector?.categories?.categories?.length > 9 && <button onClick={()=>setCategoriesOpen(!categoriesOpen)} className={`rounded-full w-[50px] h-[85px] text-center flex flex-col justify-top items-center transition duration-200`}>
            <div className={`flex items-center justify-center rounded-full w-[50px] h-[50px] transition duration-200`}>
              <div className='rounded-full w-[40px] h-[40px] border border-gray-200 bg-nadabake-purple bg-opacity-10 flex items-center justify-center'>
                {categoriesOpen ? 
                  <CloseIcon className={`w-5 h-5 text-gray-600`} />
                :
                  <PlusIcon className={`w-5 h-5 text-gray-600`} />
                }
              </div>
            </div>
            <p className={`text-sm`}>{categoriesOpen ? 'less' : 'more'}</p>
          </button>} */}
        </div>}
        {searchResults !== null && <button onClick={()=>{clearSearch()}} className='p-2 rounded flex items-center justify-center gap-x-2 text-gray-500 bg-gray-100 text-xs font-medium font-syne'>
          <CloseIcon className={`w-5 h-5`} /> Cancel search
        </button>}
  
        {menuSelector.fetchingMenus || searching 
        ?
          <Loader />
        : 
        <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 mt-12'>
         {!searchResults ? <>
          {menuSelector?.menu?.items?.map((item, itemIndex) => (
            <StoreItem key={itemIndex} item={item} />
          ))}
         </> : 
         <>
            {searchResults?.map((item, itemIndex) => (
              <StoreItem key={itemIndex} item={item} />
            ))}
         </>
         }
        </div>}
  
        {searchResults && searchResults?.length === 0 && <div className='w-full text-center p-8 lg:p-24 xl:p-44'>
          <p className='text-sm text-gray-600'>No products found for the selected category</p>
        </div>}
  
        </section>
      </Landing>
    )
}

export default Emenu