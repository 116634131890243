import React, { Fragment, useEffect, useState } from 'react'
import NavToggle from '../elements/NavToggle'
import Facebook from '../../assets/img/icons/facebook.svg'
import Twitter from '../../assets/img/icons/twitter.svg'
import { Transition, TransitionChild } from '@headlessui/react'
import BagIcon from '../elements/icons/BagIcon';
import NadabakeLogo from '../../assets/img/logo/logo.svg'
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { fetchCart } from '../../store/actions/cartActions'
import InlinePreloader from '../elements/InlinePreloader'

const Header = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const cartState = useSelector((state => state.cart))
    const pathname = location.pathname;
    const [menuActive, setMenuActive] = useState(false)
    const [showMenuExplore, setShowMenuExplore] = useState(false)
    const [showMenuFooter, setShowMenuFooter] = useState(false)

    const openMenu = () => {
        setMenuActive(true)
        setTimeout(() => {
            setShowMenuExplore(true)
        }, 500);
        
        setTimeout(() => {
            setShowMenuFooter(true)
        }, 700);
    }

    const closeMenu = () => {
        setShowMenuFooter(false)
        setTimeout(() => {
            setShowMenuExplore(false)
        }, 300);
        setTimeout(() => {
            setMenuActive(false)
        }, 500);
    }

    useEffect(() => {
        dispatch(fetchCart())
        return () => {
        
        };
    }, [dispatch]);
    return (
        <>
            <div className={`transition duration-300 w-full h-[80px] flex items-center justify-center fixed z-40 bg-white`}>
                {/* <div className='w-[100px] lg:w-1/12'>
                    <NavToggle menuActive={menuActive} openMenu={()=>{openMenu()}} closeMenu={()=>{closeMenu()}} />
                </div> */}
                <div className={`h-full border-stone-600 text-center flex items-center justify-center ${menuActive || pathname !== "/" ? 'w-6/12 lg:w-11/12' : 'w-8/12 lg:w-9/12'}`}>
                    <Link to='/'>
                        <span>
                            <div className='block relative w-full cursor-pointer pt-2'>
                                {/* <Image
                                    alt=''
                                    src={menuActive || router.pathname !== "/" ? LogoBlack : Logo}
                                /> */}
                                <div className='text-black flex items-center justify-center gap-x-2'>
                                    {/* <div className='relative inline-block w-6'>
                                        <img 
                                            alt=''
                                            src={NadabakeLogo}
                                        />
                                    </div> */}
                                    <p className='font-bold'>EXCELLERS RESTAURANT & BAR - E-MENU</p>
                                </div>
                            </div>
                            <div className='lg:hidden relative w-16 cursor-pointer pt-2 text-red-500'>
                                {/* <Image
                                    alt=''
                                    src={Ob1Icon}
                                /> */}
                                {/* N */}
                            </div>
                        </span>
                    </Link>
                </div>
                {/* <div className='flex flex-row-reverse w-4/12'>
                    {!menuActive &&  
                        <Link to="/store" className={`hidden xl:flex items-center justify-center w-full h-[80px] hover:bg-white transition duration-200 font-medium text-md hover:text-black ${!pathname.includes('/store') ? 'bg-black text-white' : 'bg-white text-nadabake-purple'}`}>
                            Explore our bakery
                        </Link>
                    }
                    <div className='w-[60px] h-[60px] mr-4 flex items-center justify-center'>
                        {cartState?.fetchingCart ? <InlinePreloader /> : <Link to={`/shopping-bag`} className='w-[50px] h-[50px] rounded-full bg-nadabake-purple bg-opacity-10 flex items-center justify-center relative'>
                            {cartState?.cart && <div className='rounded-full w-[15px] h-[15px] bg-red-500 flex items-center justify-center absolute right-0 top-0'>
                                <p className='text-white text-[12px]'>{cartState?.cart?.items?.length}</p>
                            </div>}
                            <BagIcon className={`text-nadabake-purple w-[20px] h-[20px]`} />
                        </Link>}
                    </div>
                </div> */}
            </div>

            {/* Menu */}

            <Transition show={menuActive}>
                <TransitionChild
                    // appear={true}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-y-full"
                    enterTo="translate-y-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-y-0"
                    leaveTo="-translate-y-full"
                    className='w-full h-screen z-30 bg-white fixed'
                >
                    <div>
                        <div className='px-8 lg:px24 xl:px-32 2xl:px-44 flex w-full h-screen pt-44'>
                            <div className='w-full lg:w-6/12 xl:w-4/12'>
                                <div className='w-full flex items-center gap-x-4 my-8'>
                                    {pathname === "/" && <div className='h-px w-[30px] bg-stone-500' />}
                                    <Link to={`/`} className={`${pathname === "/" ? "text-stone-500" : "text-black"} hover:translate-x-[15px] transition duration-200 font-gilroy text-xl xl:text-4xl font-medium`}>
                                        HOME
                                    </Link>
                                </div>
                                <div className='w-full flex items-center gap-x-4 my-8'>
                                    {pathname === "/store" && <div className='h-px w-[30px] bg-stone-500' />}
                                    <Link to="/store" className={`${pathname === "/store" ? "text-stone-500" : "text-black"} hover:translate-x-[15px] transition duration-200 font-gilroy text-xl xl:text-4xl font-medium`}>
                                        SHOP
                                    </Link>
                                </div>
                                {/* <div className='w-full flex items-center gap-x-4 my-8 relative' onMouseEnter={()=>{setServicesActive(true)}} onMouseLeave={()=>{setServicesActive(false)
                                }}>
                                    {pathname === "/store" && <div className='h-px w-[30px] bg-stone-500' />}
                                    <div className='lg:flex justify-between items-center relative w-56'>
                                        <Link to={`store`} className={`${pathname.includes("/services") ? "text-stone-500" : "text-black"} hover:translate-x-[15px] transition duration-200 font-gilroy text-xl xl:text-4xl font-medium cursor-pointer`}>
                                            SHOP
                                        </Link>
                                        <ChevronIcon className={`w-6 h-6 -mt-1 text-black rotate-180 hidden lg:inline-block`} />
                                        {servicesActive && <div className='w-full lg:w-56 relative lg:absolute lg:-right-60 lg:-top-12'>
                                            <div className='py-3 border-t border-b border-gray-300'>
                                                <Link to="/" className={`text-black hover:translate-x-[15px] transition duration-200 font-gilroy text-lg xl:text-xl font-medium`}>
                                                    CAKES
                                                </Link>
                                            </div>
                                            <div className='py-3 border-gray-300'>
                                                <Link to="/" className={`text-black hover:translate-x-[15px] transition duration-200 font-gilroy text-lg xl:text-xl font-medium`}>
                                                    BREAD
                                                </Link>
                                            </div>
                                            <div className='py-3 border-t border-b border-gray-300'>
                                                <Link to="/" className={`text-black hover:translate-x-[15px] transition duration-200 font-gilroy text-lg xl:text-xl font-medium`}>
                                                    DESSERTS
                                                </Link>
                                            </div>
                                        </div>}
                                    </div>
                                </div> */}
                                <div className='w-full flex items-center gap-x-4 my-8'>
                                    {pathname === "/contact-us" && <div className='h-px w-[30px] bg-stone-500' />}
                                    <Link to="/contact-us" className={`${pathname === "/contact-us" ? "text-stone-500" : "text-black"} hover:translate-x-[15px] transition duration-200 font-gilroy text-xl xl:text-4xl font-medium`}>
                                        CONTACT
                                    </Link>
                                </div>
                            </div>
                            <div className='hidden xl:block w-4/12' />

                            <div className='hidden lg:block lg:w-6/12 xl:w-4/12 text-right'>
                                
                                {showMenuExplore && 
                                    <Transition
                                        appear={true}
                                        as={Fragment}
                                        show={showMenuExplore}
                                        enter="transition ease-in-out duration-150 transform"
                                        enterFrom="opacity-0 translate-y-[50px]"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in-out duration-150 transform"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 -translate-y-[50px]"
                                    >
                                        <div>
                                            <Link to="/store">
                                                <button className='w-2/3 h-20 bg-black hover:bg-white transition duration-200 font-medium text-md text-white hover:text-black'>
                                                    Explore our bakery
                                                </button>
                                            </Link>
                                        </div>
                                    </Transition>
                                }

                                
                            </div>
                        </div>

                        {showMenuFooter && 
                            <Transition
                                appear={true}
                                show={showMenuFooter}
                                enter="transition ease-in-out duration-150 transform"
                                enterFrom="opacity-0 translate-y-[50px]"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in-out duration-150 transform"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 -translate-y-[50px]"
                            >

                                <div className='hidden lg:block w-full absolute bottom-0 left-0 px-8 lg:px-24 xl:px-44'>
                                    <div className='w-full text-right mb-44'>
                                        <p className='font-medium'>LET&apos;S TALK</p>
                                        <p className='font-light text-ston-500 mb-6'>+234 801 234 5678</p>
                                        <p className='font-medium'>GET IN TOUCH</p>
                                        <p className='font-light'>hello@nadabakehouse.com</p>
                                    </div>
                                    <div className='w-full h-20 flex'>
                                        <div className='w-full lg:flex gap-x-12'>
                                            {/* <div className=''>
                                                <Link to="/case-studies" className={`${pathname === "/case-studies" ? "text-stone-500" : "text-black"} hover:translate-y-[15px] transition duration-200 font-gilroy text-md lg:text-xl font-medium`}>
                                                    CASE STUDIES
                                                </Link>
                                                {pathname === "/case-studies" && <div className='h-px w-[200px] bg-stone-500' />}
                                            </div> */}
                                            {/* <div className=''>
                                                <Link to="/testimonials">
                                                    <a className={`${router.pathname === "/testimonials" ? "text-stone-500" : "text-black"} hover:translate-y-[15px] transition duration-200 font-gilroy text-md lg:text-xl font-medium`}>
                                                        TESTIMONIALS
                                                    </a>
                                                </Link>
                                                {router.pathname === "/testimonials" && <div className='h-px w-[200px] bg-stone-500' />}
                                            </div> */}
                                            {/* <div className=''>
                                                <Link to="/blog" className={`${pathname === "/blog" ? "text-stone-500" : "text-black"} hover:translate-y-[15px] transition duration-200 font-gilroy text-md lg:text-xl font-medium`}>
                                                    BLOG
                                                </Link>
                                                {pathname.includes("/blog") && <div className='h-px w-[200px] bg-stone-500' />}
                                            </div> */}
                                        </div>
                                        <div className='w-full flex flex-row-reverse items-center gap-x-6'>
                                            {/* <a className='relative inline-block w-6'>
                                                <Image 
                                                    alt=''
                                                    src={Linkedin}
                                                    // className='w-full'
                                                    width={24}
                                                />
                                            </a> */}
                                            <a href='https://www.facebook.com/nadabakehouse/' target="_blank" rel='noreferrer' className='relative inline-block w-6 text-center'>
                                            <img 
                                                    alt=''
                                                    src={Facebook}

                                                />
                                            </a>
                                            <a href='https://twitter.com/nadabakehouse' target='_blank' rel='noreferrer' className='relative inline-block w-6'>
                                                <img 
                                                    alt=''
                                                    src={Twitter}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </Transition>
                        }
                    </div>

                    
                </TransitionChild>
            </Transition>
        </>
    )
}

export default Header