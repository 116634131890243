import React from 'react'
import CloseIcon from './icons/CloseIcon'
import { MinusIcon } from '@heroicons/react/20/solid'
import PlusIcon from './icons/PlusIcon'
import { useDispatch, useSelector } from 'react-redux'
import { deductFromCart, sendToCart } from '../../store/actions/cartActions'
import { clientId } from '../../utils'

const ItemInBag = ({item}) => {
    const dispatch = useDispatch()
    const cartState = useSelector((state => state.cart))

    const addItemToCart = async () => {
        const newCartItem = {
            displayName: item.displayName,
            item: item.item._id,
            quantity: 1,
            price: item.price,
            parentItem: item.parentItem._id,
            parentItemCategory: item.parentItemCategory._id,
        }

        const requestPayload = {
            clientId: clientId(),
            item: newCartItem
        }
    
        dispatch(sendToCart(requestPayload))
    }

    const deductItemFromCart = async () => {
        const requestPayload = {
            clientId: clientId(),
            item: item._id,
            quantity: 1
        }
    
       dispatch(deductFromCart(requestPayload))
    }

    const removeItemFromCart = async () => {
        const requestPayload = {
            clientId: clientId(),
            item: item.item._id,
            quantity: item.quantity
        }
    
       dispatch(deductFromCart(requestPayload))
    }

    const itemInCart = () => {
        const indexOfItemInCart = cartState?.cart?.items?.findIndex((cartItem) => 
            cartItem?.item === item.item._id
        )
        return indexOfItemInCart
    }
    

    return (
        <div className="flex items-start gap-x-3 my-6">
            <div className='w-[75px] xl:w-[100px]'>
                <div className='relative h-[75px] xl:h-[100px] w-[75px] xl:w-[100px]' style={{backgroundImage: `url(${item.parentItem.coverImage})`, backgroundPosition: 'center center', backgroundSize: 'cover'}} />
            </div>

            <div className='w-full'>
                <div className='flex items-start justify-between'>
                    <div>
                        <p className='font-red-hat font-[500] text-sm lg:text-md'>{item.parentItem.name} - {item.item.name}</p>        
                        <p className='mt-1 font-[500] text-sm'>x{item.quantity}</p>
                    </div>
                    <button onClick={()=>{removeItemFromCart()}} className='flex items-center gap-x-2 text-xs'>
                        <CloseIcon className={`w-5 h-5 text-gray-400`} />
                        <span className='hidden lg:inline-block'>Remove item</span>
                    </button>
                </div>
                <div className='flex flex-row-reverse'>
                    <button onClick={()=>addItemToCart()} className='flex items-center gap-x-2 text-xs px-2'>
                        <PlusIcon className='w-5 h-5 text-gray-500' />
                    </button>
                    <p className='font-thin text-lg'>{cartState?.cart?.items[itemInCart()]?.quantity}</p>
                    <button  onClick={()=>deductItemFromCart()} className='flex items-center gap-x-2 text-xs px-2'>
                        <MinusIcon className='w-5 h-5 text-gray-500' />
                    </button>
                </div> 
            </div>

        </div>
    )
}

export default ItemInBag