import React, { useEffect, useState } from 'react'
import Landing from '../components/layout/Landing'
import ItemInBag from '../components/elements/ItemInBag';
import RadioGroup from '../components/elements/form/RadioGroup';
import TextField from '../components/elements/form/TextField';
import { useDispatch, useSelector } from 'react-redux'
import { checkoutCart, fetchCart } from '../store/actions/cartActions'
import Loader from '../components/elements/Loader'
import { Link } from 'react-router-dom'
import { fetchStoreSettings } from '../store/actions/settingsActions';
import { storeId } from '../utils';
import SelectField from '../components/elements/form/SelectField';
import { ERROR } from '../store/types';
import InlinePreloader from '../components/elements/InlinePreloader';
import { fetchStoreMenu } from '../store/actions/menuActions';

const ShoppingBag = () => {
  // Steps
  // 1. Cart preview
  // 2. Select delivery address (or create one)
  // 3. Select payment method (Card payment, payment on delivery)
  // 4. Success/failure

  const dispatch = useDispatch()
  const menuState = useSelector((state => state.menu))
  const cartState = useSelector((state => state.cart))
  const settingsState = useSelector((state => state.settings))

  useEffect(() => {
    dispatch(fetchStoreMenu())
    dispatch(fetchCart())
    dispatch(fetchStoreSettings())
    return () => {
    };
  }, [dispatch]);

  const [activeStep, setActiveStep] = useState(1);
  const [activePaymentOption, setActivePaymentOption] = useState(null);
  const [deliveryAddress, setDeliveryAddress] = useState({});

  const paymentOptions = [
    // {
    //   label: 'Card Payment'
    // },
    {
      label: 'Cash on delivery or pickup',
      value: 'CASH_ON_DELIVERY'
    },
    {
      label: 'POS on delivery or pickup',
      value: 'POS_ON_DELIVERY'
    },
  ]

  const deliveryOptions = [
    {
      label: 'Doorstep delivery',
      value: 'DOORSTEP'
    },
    {
      label: 'Pickup at an outlet',
      value: 'PICKUP'
    }
  ]

  const [activeDeliveryOption, setActiveDeliveryOption] = useState(null);
  const [userDetails, setUserDetails] = useState({});

  const [selectedOutlet, setSelectedOutlet] = useState(null);

  const totalAmount = () => {
    const totalPrice = cartState?.cart?.items.reduce((a, b) => a + (b.price * b.quantity || 0), 0)
    const vat = totalPrice * 0.075
    return {
      total: totalPrice, 
      vat: vat
    }
  }

  const [deliveryCost, setDeliveryCost] = useState(0);

  const [validationErrors, setValidationErrors] = useState();


  const validateForm = () => {
    let errors = {}
    if (!userDetails.name || userDetails.name === '') {
        errors.name = true
    }

    if (!userDetails.email || userDetails.email === '') {
        errors.email = true
    }

    if (!userDetails?.phone || userDetails.phone === '') {
        errors.phone = true
    }

    if(!activePaymentOption){
      errors.paymentOption = true
    }

    if(!activeDeliveryOption){
      errors.deliveryOption = true
    }

    if(activeDeliveryOption === 'DOORSTEP') {
      if (!deliveryAddress.address || userDetails.address === '') {
        errors.address = true
      }

      if (!deliveryAddress.city || deliveryAddress.city === '') {
          errors.city = true
      }

      if (!deliveryAddress?.state || userDetails.state === '') {
          errors.state = true
      }
    }

    setValidationErrors(errors)
    return errors
  }

  const checkout = () => {
    if (Object.values(validateForm()).includes(true)) {
      dispatch({
          type: ERROR,
          error: {response: {data: {
              message: 'Please check the highlighted fields'
          }}}
      });
      return
    }

    const payload = {
      deliveryType: activeDeliveryOption,
      paymentMethod: activePaymentOption,
      sourceMenu: menuState.menu._id,
      store: storeId,
      orderBy: userDetails
    }

    if(payload.deliveryType === 'DOORSTEP') {
      payload.deliveryAddress = deliveryAddress
    }

    if(payload.deliveryType === 'PICKUP') {
      payload.pickupOutlet = storeId
    }

    dispatch(checkoutCart(cartState.cart._id, payload))
  }
  return (
    <Landing>
      {
        cartState?.fetchingCart ? 
          <Loader />
        :
        <>
          {!cartState?.order && <div className='min-h-screen h-inherit bg-gray-100 p-4 lg:p-24 xl:p-32 pt-[100px]'>
            {activeStep === 1 && 
              <div className='w-full'>
                <div className='w-11/12 lg:w-8/12 xl:w-7/12 2xl:w-5/12 mx-auto p-8 bg-white'>
                  {cartState?.cart?.items.length > 0 ? <div className=''>
                    <h3 className='font-[600]'>Shopping bag</h3>
                    <p className='mt-2 mb-2 pb-4 border-b border-gray-300'>You can add or remove items from your bag by clicking the +/- buttons</p>

                    {cartState?.cart?.items?.map((item, itemIndex) => (
                      <ItemInBag item={item} key={itemIndex} />
                    ))}
                      <div className='mb-4 mt-12  flex gap-x-2'>
                        <p className="uppercase tracking-[0.2em] text-gray-300">total:</p>
                        <p className="uppercase tracking-[0.1em] text-nadabake-purple text-2xl">N{totalAmount().total.toLocaleString()}</p>
                      </div>
                    <button onClick={()=>{setActiveStep(2)}} className='w-full bg-nadabake-purple text-white p-5 text-center font-[500] font-syne'>Proceed to Checkout</button>
                  </div>
                  :
                  <div className='w-full text-center'>
                    <p className='text-sm text-gray-500'>You do not have any items in your bag yet. <Link to={`/store`} className="text-nadabake-purple font-[500]">Click here</Link> to go to the store.</p>
                  </div>
                  }
                </div>
              </div>
            }
            {activeStep === 2 && 
              <div className='w-full xl:flex items-start gap-x-8'>
                <div className='w-full xl:w-7/12'>
                  <div className='p-8 bg-white mx-auto mb-8 mt-4'>
                    <p className="uppercase tracking-[0.2em] text-gray-600">YOUR DETAILS</p>
                    <p className='mt-2 mb-2 pb-4 border-b border-gray-300 text-sm'>Your name and contact details</p>

                    <div className="p-3">
                      <p className='mt-2 mb-2 pb-4 border-gray-300 text-sm'>Please provide your delivery address</p>

                      <div className="xl:flex items-center justify-between gap-x-8 my-6">
                        <div className='w-full'>
                          <TextField
                            inputType="text" 
                            fieldId={`name`}
                            inputLabel="Full name" 
                            preloadValue={''}
                            hasError={validationErrors?.name} 
                            returnFieldValue={(value)=>{setUserDetails({...userDetails, ...{name: value}})}}
                          />
                        </div>
                      </div>
                      <div className="xl:flex items-center justify-between gap-x-8 my-6">
                        <div className='w-full'>
                          <TextField
                            inputType="text" 
                            fieldId={`phone`}
                            inputLabel="Phone number" 
                            preloadValue={''}
                            hasError={validationErrors?.phone} 
                            returnFieldValue={(value)=>{setUserDetails({...userDetails, ...{phone: value}})}}
                          />
                        </div>
                        <div className='w-full mt-6 xl:mt-0'>
                          <TextField
                            inputType="text" 
                            fieldId={`email`}
                            inputLabel="Email address" 
                            preloadValue={''}
                            hasError={validationErrors?.email} 
                            returnFieldValue={(value)=>{setUserDetails({...userDetails, ...{email: value}})}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='p-8 bg-white mx-auto mb-8 mt-4'>
                    <p className="uppercase tracking-[0.2em] text-gray-600">DELIVERY OPTIONS</p>
                    <p className='mt-2 mb-2 pb-4 border-b border-gray-300 text-sm'>How would you like your ordered items to get to you?</p>

                    <RadioGroup
                      items={deliveryOptions}
                      returnSelected={(selected)=>{setActiveDeliveryOption(selected.value)}}
                      inline={true}
                      hasError={validationErrors?.deliveryOption}
                    />

                    {activeDeliveryOption === 'DOORSTEP' && <div className="p-3">
                      <p className='mt-2 mb-2 pb-4 border-gray-300 text-sm'>Please provide your delivery address</p>

                      <div className="flex items-center justify-between gap-x-8 my-6">
                        <div className='w-full'>
                          <TextField
                            inputType="text" 
                            fieldId={`address`}
                            inputLabel="Address" 
                            preloadValue={''}
                            hasError={validationErrors?.address} 
                            returnFieldValue={(value)=>{setDeliveryAddress({...deliveryAddress, ...{address: value}})}}
                          />
                        </div>
                      </div>

                      <div className="xl:flex items-start justify-between gap-x-8 my-6">
                        {settingsState?.settings?.deliveryCharges?.charges?.length > 0 && <div className='w-full'>
                          <SelectField
                            selectOptions={settingsState?.settings?.deliveryCharges?.charges}
                            inputLabel="Select delivery city"
                            titleField="location"
                            displayImage={false}
                            imageField=""
                            preSelected=''
                            fieldId="delivery-location"
                            hasError={validationErrors?.city}
                            // return id of accounts of the selected option
                            returnFieldValue={(value) => {
                              setDeliveryAddress({...deliveryAddress, ...{city: value.location}})
                              setDeliveryCost(value.charge)
                            }}
                          />
                          <label className='block text-xs mt-2 text-nadabake-purple px-1'>Please select one of the locations above. We only deliver to these locations</label>
                        </div>}
                        <div className='w-full mt-6 xl:mt-0'>
                          <TextField
                            inputType="text" 
                            fieldId={`state`}
                            inputLabel="State" 
                            preloadValue={''}
                            hasError={validationErrors?.state} 
                            returnFieldValue={(value)=>{setDeliveryAddress({...deliveryAddress, ...{state: value}})}}
                          />
                        </div>
                      </div>
                      
                      <div className="flex items-center justify-between gap-x-8 my-6">
                        <div className='w-full'>
                          <TextField
                            inputType="text" 
                            fieldId={`description`}
                            inputLabel="Description or landmark" 
                            preloadValue={''}
                            hasError={false} 
                            returnFieldValue={(value)=>{setDeliveryAddress({...deliveryAddress, ...{description: value}})}}
                          />
                        </div>
                      </div>


                    </div>}

                    {activeDeliveryOption === 'PICKUP' && <div className="p-3">
                      <p className='mt-2 mb-2 pb-4 border-gray-300 text-sm'>Please select the closest outlet to your location</p>

                      <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
                      {/* {outlets.map((outlet, outletIndex) => ( */}
                        <div onClick={()=>{setSelectedOutlet(settingsState?.settings?.storeDetails?._id)}} className={`cursor-pointer w-full p-6 rounded transition duration-200 border ${selectedOutlet === settingsState?.settings?.storeDetails?._id ? 'border-nadabake-purple bg-purple-100 bg-opacity-50' : 'border-transparent bg-white'}`}>
                          <p className="uppercase tracking-[0.2em] text-gray-800">{settingsState?.settings?.storeDetails?.name}</p>
                          <p className='mt-2 text-sm'>{settingsState?.settings?.storeDetails?.address}</p>
                          {/* <p className='mt-1 text-sm'>{outlet.workingHours}</p> */}
                        </div>
                      {/* ))  */}
                      {/* } */}
                      </div>

                    </div>}

                  </div>
                  <div className='p-8 bg-white mx-auto mb-8'>
                    <p className="uppercase tracking-[0.2em] text-gray-600">PAYMENT METHOD</p>
                    <p className='mt-2 mb-2 pb-4 border-b border-gray-300 text-sm'>Please select your preferred method of payment for this order.</p>

                    <RadioGroup
                      items={paymentOptions}
                      returnSelected={(value)=>{setActivePaymentOption(value.value)}}
                      inline={true}
                      hasError={validationErrors?.paymentOption}
                    />

                  </div>
                </div>
                <div className='w-full xl:w-5/12 mx-auto p-8 bg-white mt-4'>
                  <div className=''>
                    <p className="uppercase tracking-[0.2em] text-gray-600">checkout items</p>
                    <p className='mt-2 mb-2 pb-4 border-b border-gray-300 text-sm'>You can add or remove items from your bag by clicking the +/- buttons</p>

                    {cartState?.items?.items.map((item, itemIndex) => (
                      <ItemInBag item={item} key={itemIndex} />
                    ))}
                      <div className='mb-4 mt-12 flex gap-x-2 justify-between'>
                        <p className="uppercase tracking-[0.2em] text-gray-500 text-sm">Subtotal:</p>
                        <p className="uppercase tracking-[0.1em] text-nadabake-purple text-lg">N{totalAmount()?.total?.toLocaleString()}</p>
                      </div>
                      <div className='mb-4 mt-2 flex gap-x-2 justify-between'>
                        <p className="uppercase tracking-[0.2em] text-gray-500 text-sm">VAT:</p>
                        <p className="uppercase tracking-[0.1em] text-nadabake-purple text-lg">N{totalAmount()?.vat?.toLocaleString()}</p>
                      </div>
                      {activeDeliveryOption === 'DOORSTEP' && deliveryAddress.city && <div className='mb-4 mt-2 flex gap-x-2 justify-between'>
                        <p className="uppercase tracking-[0.2em] text-gray-500 text-sm">Delivery:</p>
                        <p className="uppercase tracking-[0.1em] text-nadabake-purple text-lg">N{deliveryCost?.toLocaleString()}</p>
                      </div>}
                      <div className='mb-4 mt-12 flex gap-x-2 justify-between'>
                        <p className="uppercase tracking-[0.2em] text-gray-500 text-s,">Total:</p>
                        <p className="uppercase tracking-[0.1em] text-nadabake-purple text-2xl">N{(totalAmount().total + totalAmount().vat + deliveryCost).toLocaleString()}</p>
                      </div>

                    <button onClick={()=>{checkout()}} className='w-full bg-nadabake-purple text-white p-5 text-center font-[500] font-syne flex items-center justify-center'>
                      {cartState.checkingOut ? <InlinePreloader /> : 'Complete your order'}
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>}

          {cartState?.order && <div className='min-h-screen h-inherit bg-gray-100 p-4 lg:p-24 xl:p-32 pt-[100px]'>
            <div className='w-full'>
              <div className='w-11/12 lg:w-8/12 xl:w-7/12 2xl:w-5/12 mx-auto p-8 bg-white'>
                <div className=''>
                  <h3 className='font-[600]'>Thank you</h3>
                  <p className='mt-2 mb-2 pb-4 border-b border-gray-300'>Your order has been placed and is currently being prepared</p>

                  <p className='mt-2 mb-2 pb-4 border-b border-gray-300'>You will be required to pay the following total at delivery or pickup </p>
                  
                  <div className='mb-4 mt-12 flex gap-x-2'>
                    <p className="uppercase tracking-[0.2em] text-gray-300">total:</p>
                    <p className="uppercase tracking-[0.1em] text-nadabake-purple text-2xl">N{(cartState?.order?.total + deliveryCost + cartState?.order?.vat).toLocaleString() }<span className='text-[14px]'>({cartState?.order?.vat.toLocaleString()} VAT)</span></p>
                  </div>
                  <Link to={`/store`} className='bg-nadabake-purple bg-opacity-10 text-nadabake-purple mt-8 p-5 text-center font-[500] font-syne block w-full'>Return to store</Link>
                </div>
              </div>
            </div>
          </div>}
        </>

      }
    </Landing>
  )
}

export default ShoppingBag